import { ArrowBack } from "@mui/icons-material";
import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { CompanyLogo, Button } from "../../../../Components/Atoms";
import { Grid, Input, TextField, Typography } from "@mui/material";
import { useToast } from "../../../../Hooks/toast";
import { VerificationContainer } from "../../ResetPassword/styles";
import { MyFirstAccessContext } from "..";
import {
    MdErrorOutline,
    MdCheckCircleOutline
} from 'react-icons/md'

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const validatePassword = ({ password = '', confirmationPassword = '', transactionalPassword = '' }) => [
    [password !== '', 'Senha de acesso é obrigatório'],
    [password.length >=8, 'Senha de acesso deve conter oito caractéres'],
    [confirmationPassword === password && confirmationPassword && password, 'As senhas de acesso precisam ser iguais'],
    [transactionalPassword !== '', 'Senha transacional é obrigatório'],
    [transactionalPassword.length === 4, 'Senha transacional deve ter quatro digitos'],
    [transactionalPassword && /\d{4}/gi.test(transactionalPassword), 'Senha transacional deve ser somente número']
]

export const ChangePassword: React.FC = () => {
    const history = useHistory();

    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [transactionalPassword, setTransactionalPassword] = useState("");
    const [disabled, setDisabled] = useState(false);

    const timeoutRef = useRef<any>(undefined);

    const { email, changePassword } = useContext(MyFirstAccessContext);

    const { addToast } = useToast();

    const errors = useMemo(() => 
        validatePassword({ password, confirmationPassword, transactionalPassword }), [password, confirmationPassword, transactionalPassword]);

    const isInvalid = useMemo(() => errors.some(([success]) => !success), [errors]);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, [])

    const handleSubmit = async () => {
        setDisabled(true);

        const isSuccessful = await changePassword(email, password, transactionalPassword);

        if(!isSuccessful) {
            setDisabled(false);
            return addToast({
                title: 'Erro',
                description: 'Erro ao mudar a senha da sua conta.',
                type: 'error'
            })
        }

        addToast({
            title: 'Sucesso',
            description: 'Senha resetada com sucesso, você será redirecionado para fazer login',
            type: 'success'
        })

        timeoutRef.current = setTimeout(() => {
            history.push('/auth');
        }, 5000);
    }

    return (
        <VerificationContainer>
            <CompanyLogo width={200} height={60} />
            <p>Crie sua nova senha</p>
            <p>Informe abaixo sua nova senha de acesso:</p>
            <Grid
                container
                spacing={2}
                flexDirection="column"
                alignItems="center"
            >
                <Grid item>
                    <TextField
                        fullWidth
                        value={password}
                        type="password"
                        name="password"
                        placeholder="Senha de Acesso"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.currentTarget.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        value={confirmationPassword}
                        type="password"
                        name="confirmationPassword"
                        placeholder="Confirmar senha"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setConfirmationPassword(e.currentTarget.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        value={transactionalPassword}
                        type="text"
                        inputProps={{ maxLength: 4 }}
                        name="transactionalPassword"
                        placeholder="Senha transacional"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setTransactionalPassword(e.currentTarget.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <ul style={{ listStyle: 'none' }}>
                        {
                            errors.map(([success, message], i) => (
                                <li key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '12px', color: success ? 'green' : 'red' }}>
                                    { success ? <MdCheckCircleOutline/> : <MdErrorOutline/> }
                                    <span style={{ paddingLeft: '5px' }}>{message}</span>
                                </li>
                            ))
                        }
                    </ul>
                </Grid>
                <Grid item>
                    <Button disabled={isInvalid || disabled} onClick={handleSubmit}>
                        Redefinir senha
                    </Button>
                </Grid>
            </Grid>
        </VerificationContainer>
    );
};